import React, { Component } from 'react';
import Navigation from '../../components/navigation/Navigation';
import Footer from '../../components/footer/Footer';
import { Nav } from 'react-bootstrap';
import './ServicesDetails.scss';
import Img from '../../assets/images/image-guerra-3-2.jpeg';
import ImgServiceAgricola from '../../assets/images/image-guerra-45.jpeg';
import FormContact from '../../components/contact/FormContact';

class ServicesDetails extends Component {
    state = {
        serviceId: this.props.match.params.id
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    changeTab = (serviceId) => {
        this.setState({ serviceId: serviceId })
    }

    render() {
        return (
            <div className="service-details-fragment">
                <Navigation external={true} history={this.props.history} />
                <div className="container-fluid service-details-section">
                    <div className="container separated-section">
                        <div className="row">
                            <div className="col-12 title-section">
                                <h4>SERVICIO</h4>
                            </div>
                        </div>
                        <div className="row desktop">
                            <div className="col-12">
                                <Nav variant="tabs" className="justify-content-end">
                                    <Nav.Item className={`nav-link ${this.state.serviceId === '1' && 'active'}`}
                                        onClick={() => this.changeTab('1')}>
                                        <Nav.Link>TRANSPORTE DE CARGA</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={`nav-link ${this.state.serviceId === '2' && 'active'}`}
                                        onClick={() => this.changeTab('2')}>
                                        <Nav.Link>SERVICIOS AGRÍCOLAS</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        {this.state.serviceId === '1' && <Service1 />}
                        {this.state.serviceId === '2' && <Service2 />}
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 quotation">
                            <h4>Cotización</h4>
                        </div>
                        <div className="col-12 col-md-6 ml-auto mr-auto">
                            <FormContact serviceId={this.state.serviceId} />
                        </div>
                    </div>
                </div>
                <Footer external={true} history={this.props.history} />
            </div>
        );
    }
}

export default ServicesDetails;

const Service1 = () => (
    <div className="row service-details">
        <div className="col-12 col-md-4 order-2 order-md-1">
            <img src={Img} />
        </div>
        <div className="col-12 col-md-8 order-1 order-md-2">
            <label>Transporte de cargas</label>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur efficitur
                tincidunt diam, eu dapibus urna auctor in. Morbi eu lorem et dui ultrices
                malesuada. Vestibulum aliquam risus sit amet condimentum scelerisque. Etiam
                iaculis ultrices eros, eu condimentum magna tempor quis. Quisque sed tristique
                dui. Quisque volutpat luctus luctus. Integer ac suscipit felis. In id velit in
                eros scelerisque bibendum. Duis a venenatis velit. Interdum et malesuada fames
                ac ante ipsum primis in faucibus. Quisque condimentum vulputate tortor, et ultricies
                orci. Praesent scelerisque elit ac ornare luctus.
            </p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur efficitur
                tincidunt diam, eu dapibus urna auctor in. Morbi eu lorem et dui ultrices
                malesuada. Vestibulum aliquam risus sit amet condimentum scelerisque. Etiam
                iaculis ultrices eros, eu condimentum magna tempor quis. Quisque sed tristique
                dui. Quisque volutpat luctus luctus. Integer ac suscipit felis. In id velit in
                eros scelerisque bibendum. Duis a venenatis velit. Interdum et malesuada fames
                ac ante ipsum primis in faucibus. Quisque condimentum vulputate tortor, et ultricies
                orci. Praesent scelerisque elit ac ornare luctus.
            </p>
        </div>
    </div>
);

const Service2 = () => (
    <div className="row service-details">
        <div className="col-12 col-md-4 order-2 order-md-1">
            <img src={ImgServiceAgricola} />
        </div>
        <div className="col-12 col-md-8 order-1 order-md-2">
            <label>Servicios agrícolas</label>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur efficitur
                tincidunt diam, eu dapibus urna auctor in. Morbi eu lorem et dui ultrices
                malesuada. Vestibulum aliquam risus sit amet condimentum scelerisque. Etiam
                iaculis ultrices eros, eu condimentum magna tempor quis. Quisque sed tristique
                dui. Quisque volutpat luctus luctus. Integer ac suscipit felis. In id velit in
                eros scelerisque bibendum. Duis a venenatis velit. Interdum et malesuada fames
                ac ante ipsum primis in faucibus. Quisque condimentum vulputate tortor, et ultricies
                orci. Praesent scelerisque elit ac ornare luctus.
            </p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur efficitur
                tincidunt diam, eu dapibus urna auctor in. Morbi eu lorem et dui ultrices
                malesuada. Vestibulum aliquam risus sit amet condimentum scelerisque. Etiam
                iaculis ultrices eros, eu condimentum magna tempor quis. Quisque sed tristique
                dui. Quisque volutpat luctus luctus. Integer ac suscipit felis. In id velit in
                eros scelerisque bibendum. Duis a venenatis velit. Interdum et malesuada fames
                ac ante ipsum primis in faucibus. Quisque condimentum vulputate tortor, et ultricies
                orci. Praesent scelerisque elit ac ornare luctus.
            </p>
        </div>
    </div>
);