import React, { Component } from 'react';
import Navigation from '../../components/navigation/Navigation';
import Services from '../../components/services/Services';
import About from '../../components/about/About';
import Gallery from '../../components/gallery/Gallery';
import Contact from '../../components/contact/Contact';
import Footer from '../../components/footer/Footer';
import CarouselPhotos from '../../components/carousel/CarouselPhotos';

class Home extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Navigation history={this.props.history} />
                <CarouselPhotos />
                <Services />
                <About />
                <Gallery />
                <Contact />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Home;