import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Navigation.scss';

import logo from '../../assets/logo@3x.png';
import location from '../../assets/icons/location.svg';
import envelope from '../../assets/icons/envelope-solid.svg';
import phone from '../../assets/icons/phone-solid.svg';
import SmoothScrolling from '../smoothScrolling/SmoothScrolling';

class Navigation extends Component {
    state = {
        activePage: 1,
        isOpen: false,
        isMobile: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.setPosition, { passive: true });
    }

    setPosition = () => {
        if (window.pageYOffset < 100 || (this.state.isMobile && window.pageYOffset < 20)) {
            this.setState({ activePage: 1 })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setPosition);
    }

    scrollTo = async (section, sectionId) => {
        if (this.props.external) {
            this.props.history.push('/')
        }
        if (this.state.isMobile) {
            await this.setState({ activePage: sectionId, isOpen: !this.state.isOpen });
            setTimeout(() => {
                SmoothScrolling.scrollTo(section);
            }, this.props.external ? 1000 : 500);
        }
        else {
            await this.setState({ activePage: sectionId });
            setTimeout(() => {
                SmoothScrolling.scrollTo(section);
            }, this.props.external ? 1000 : 100);
        }
        document.body.style.overflow = 'unset';
    }

    navItems = () => (
        <React.Fragment>
            <Nav.Item>
                <Nav.Link className={`item ${this.state.activePage === 1 && 'active'}`}
                    onClick={() => this.scrollTo('home', 1)}>
                    Inicio
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={`item ${this.state.activePage === 2 && 'active'}`}
                    onClick={() => this.scrollTo('services', 2)}>
                    Servicios
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={`item ${this.state.activePage === 3 && 'active'}`}
                    onClick={() => this.scrollTo('about', 3)}>
                    Nosotros
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={`item ${this.state.activePage === 4 && 'active'}`}
                    onClick={() => this.scrollTo('gallery', 4)}>
                    Galería
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={`item ${this.state.activePage === 5 && 'active'}`}
                    onClick={() => this.scrollTo('contact', 5)} style={{ borderBottom: 'none' }}>
                    Contacto
                </Nav.Link>
            </Nav.Item>
        </React.Fragment>
    );

    detailsItems = () => (
        <React.Fragment>
            <Nav.Link className="item" href="#deets">
                <img src={location} aria-hidden="true" />
                <a target="_blank" href="https://www.google.com/maps/place/Rinc%C3%B3n+del+Pino,+Departamento+de+San+Jos%C3%A9/@-34.5023878,-56.8368244,17z/data=!3m1!4b1!4m5!3m4!1s0x95a181591761822f:0x2933effb32371c76!8m2!3d-34.502788!4d-56.8346253">
                    Rincón del Pino, San José
                </a>
            </Nav.Link>
            <Nav.Link className="item" href="#deets">
                <img src={envelope} aria-hidden="true" />
                <a href="mailto:transp-guerra@hotmail.com">transp-guerra@hotmail.com</a>
            </Nav.Link>
            <Nav.Link className="item" href="#deets">
                <img src={phone} aria-hidden="true" />
                <a href="tel:+59899000000">+598 91 209 496</a>
            </Nav.Link>
        </React.Fragment>
    );

    handleButton = async () => {
        await this.setState({ isOpen: !this.state.isOpen, isMobile: true });
        if (this.state.isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }

    render() {
        return (
            <div id='home'>
                <a href="https://wa.me/59891209496?text=Hola%20,%20tengo%20una%20consulta"
                    className="whatsapp" target="_blank"> <i className="fa fa-whatsapp"></i>
                </a>
                <Navbar collapseOnSelect expand="lg" className="navbar" expanded={this.state.isOpen}>
                    <Container>
                        <Navbar.Brand onClick={() => this.scrollTo('home', 1)}>
                            <img
                                src={logo}
                                className="d-inline-block align-top logo"
                                width="250"
                                alt="logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle className="button-menu" aria-controls="responsive-navbar-nav"
                            onClick={this.handleButton} />
                        <Navbar.Collapse className="right-align" id="responsive-navbar-nav">
                            <Nav className="desktop">
                                <this.detailsItems />
                            </Nav>
                            <div className="mobile">
                                <Nav className="flex-column">
                                    <this.navItems />
                                </Nav>
                                <div className="info">
                                    <this.detailsItems />
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container className="desktop">
                    <Nav className="nav-menu">
                        <this.navItems />
                    </Nav>
                </Container>
            </div>
        );
    }
}

export default Navigation;