import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class GoogleMap extends Component {
    render() {
        return (
            <Map google={window.google} style={{ maxWidth: '100%' }}
                initialCenter={{
                    lat: -34.5023878,
                    lng: -56.8368244
                }}>
                <Marker
                    title={'Transporte Guerra'}
                    position={{ lat: -34.5023878, lng: -56.8368244 }} />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBRqcabWXM6tmqV36R_x_r_6G4zi4gNU-Y'
})(GoogleMap);