import React, { Component } from 'react';
import './Services.scss';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImgTranport from '../../assets/images/image-guerra-3.jpeg';
import ImgServiceAgricola from '../../assets/images/image-guerra-45.jpeg';

class Services extends Component {
    state = {
        services: [
            {
                id: 1,
                img: ImgTranport,
                title: 'Transporte de cargas',
                description: `Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nam scelerisque quis quam
                non cursus. Etiam laoreet erat lacus, a varius enim suscipit sed.`
            },
            {
                id: 2,
                img: ImgServiceAgricola,
                title: 'Servicios agrícolas',
                description: `Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nam scelerisque quis quam
                non cursus. Etiam laoreet erat lacus, a varius enim suscipit sed.`
            }
        ]
    }

    render() {
        return (
            <div className="container separated-section" id="services">
                <div className="row justify-content-center">
                    <div className="col-12 title-section">
                        <h4>NUESTROS SERVICIOS</h4>
                    </div>
                    {
                        this.state.services.map(service =>
                            <div className="col-12 col-md-4">
                                <Card>
                                    <Card.Img variant="top" src={service.img} />
                                    <Card.Body>
                                        <Card.Title>{service.title}</Card.Title>
                                        <Card.Text>
                                            {service.description}
                                        </Card.Text>
                                        <Link to={`/services/${service.id}`} className="view-plus">Ver más</Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Services;