import React from 'react';
import 'bootstrap/scss/bootstrap.scss';
import 'font-awesome/scss/font-awesome.scss';
import './Styles.scss';
import Home from './pages/home/Home';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import GalleryCollection from './pages/gallery/GalleryCollection';
import ServicesDetails from './pages/Service/ServicesDetails';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/gallery" component={GalleryCollection} />
          <Route exact path="/services/:id" component={ServicesDetails} />
          <Route component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
