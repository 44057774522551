import React, { Component } from 'react';
import Navigation from '../../components/navigation/Navigation';
import './GalleryCollection.scss';
import Footer from '../../components/footer/Footer';
import { images } from '../../Constants';
import searchIcon from '../../assets/icons/search.svg';
import { Modal, Carousel } from 'react-bootstrap';

class GalleryCollection extends Component {
    state = {
        isOpen: false,
        index: 0
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    openModal = (index) => {
        this.setState({ isOpen: !this.state.isOpen, index: index })
    }

    handleSelect = (selectedIndex) => {
        this.setState({ index: selectedIndex })

    };

    render() {
        return (
            <div className="gallery-collection">
                <Navigation external={true} history={this.props.history} />
                <div className="container-fluid galleryCollection-section">
                    <div className="container separated-section">
                        <div className="row">
                            <div className="col-12 title-section">
                                <h4>GALERÍA</h4>
                            </div>
                        </div>
                        <div className="row">
                            {images.map((image, index) =>
                                <div className="col-6 col-md-4 images" onClick={() => this.openModal(index)} key={index}>
                                    <img src={image.thumb} />
                                    <div className="hoverImage">
                                        <div>
                                            <img src={searchIcon} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={this.state.isOpen}
                    onHide={this.openModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="gallery-carousel"
                >
                    <Modal.Body>
                        <Carousel activeIndex={this.state.index} onSelect={this.handleSelect}>
                            {images.map((image) =>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={image.src}
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </Modal.Body>
                </Modal>
                <Footer external={true} history={this.props.history} />
            </div>
        );
    }
}

export default GalleryCollection;