import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import ImgBanner1 from '../../assets/images/image-guerra-1.jpeg';
import ImgBanner2 from '../../assets/images/image-guerra-15.jpeg';
import ImgBanner3 from '../../assets/images/image-guerra-42.jpg';

import './CarouselPhotos.scss';

class CarouselPhotos extends Component {
    state = {
        photos: [
            {
                img: ImgBanner1
            },
            {
                img: ImgBanner2
            },
            {
                img: ImgBanner3
            }
        ]
    }

    render() {
        return (
            <Carousel nextIcon={false} prevIcon={false} className="carousel-home">
                {
                    this.state.photos.map(photo =>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={photo.img}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    )
                }
            </Carousel>
        );
    }
}

export default CarouselPhotos;