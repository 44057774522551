import React, { Component } from 'react';
import './Gallery.scss';
import Img from '../../assets/images/image-guerra-44.jpg';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import iconCamera from '../../assets/icons/camera-solid.svg';

class Gallery extends Component {
    render() {
        return (
            <div className="container-fluid separated-section gallery-section" id="gallery">
                <div className="row">
                    <div className="col-12 title-section">
                        <h4>GALERÍA</h4>
                    </div>
                    <div className="col-12 image">
                        <img
                            src={Img}
                            alt="image"
                            className="image-background"
                        />
                        <div className="gallery-details">
                            <div>
                                <span>Conocé nuestra flota</span>
                                <br />
                                <Button className="button">
                                    <Link to="/gallery">
                                        <img src={iconCamera} />
                                        <span>Ver galería</span>
                                    </Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;