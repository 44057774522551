import React, { Component } from 'react';
import './About.scss';

import Img from '../../assets/images/image-guerra-6.jpeg';
import Img2 from '../../assets/images/image-guerra-7.jpeg';

import { Carousel } from 'react-bootstrap';

class About extends Component {
    state = {
        imgs: [Img2, Img],
        about: '',
        ourMission: '',
        ourVision: ''
    }

    render() {
        return (
            <div className="container-fluid about-section" id="about">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-2 padding-not">
                        <div className="image">
                            <Carousel className="carousel-about">
                                {
                                    this.state.imgs.map(photo =>
                                        <Carousel.Item>
                                            <img
                                                src={photo}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                    )
                                }
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 about order-md-2 order-1">
                        <div className="row">
                            <div className="col-12">
                                <h4>SOBRE NOSOTROS</h4>
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                    accusantium doloremque laudantium, totam rem aperiam, eaque
                                    ipsa quae ab illo inventore veritatis et quasi architecto beatae.
                                </p>
                            </div>
                            <div className="col-12 item">
                                <span>NUESTRA MISIÓN</span>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam.
                                </p>
                            </div>
                            <div className="col-12 item">
                                <span>NUESTRA VISIÓN</span>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default About;