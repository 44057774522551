import React from 'react';
import './Loading.scss';
import logo from '../../assets/logo@3x.png'

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <img src={logo} className="image" />
            </div>
        </div>
    );
};

export default Loading;