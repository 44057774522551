import React from 'react';
import ReactDOM from 'react-dom';
import './Alert.scss';

const Alert = (props) => {
    return (
        ReactDOM.createPortal
            (
                <div className="alert-container">
                    <div className="alert-content">
                        <div class="alert" role="alert">
                            {
                                props.status ?
                                    <i className="fa fa-check" />
                                    : <i className="fa fa-times" />
                            }
                            <span className="ml-1">{props.message}</span>
                        </div>
                    </div>
                </div>
                , document.getElementById('alert')
            )

    );
};

export default Alert;