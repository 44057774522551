import React, { Component } from 'react';
import './Footer.scss';
import logoLight from '../../assets/logoLight@3x.png';
import SmoothScrolling from '../smoothScrolling/SmoothScrolling';

class Footer extends Component {

    scrollTo = (section) => {
        if (this.props.external) {
            this.props.history.push('/');
        }
        setTimeout(() => {
            SmoothScrolling.scrollTo(section);
        }, this.props.external ? 1000 : 100);
    }

    render() {
        return (
            <div className="container-fluid separated-section footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-3 image">
                            <div>
                                <img src={logoLight} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 footer-navigation">
                            <div className="options">
                                <a onClick={() => this.scrollTo('home')}>Inicio</a>
                                <a onClick={() => this.scrollTo('services')}>Servicios</a>
                                <a onClick={() => this.scrollTo('about')}>Nosotros</a>
                                <a onClick={() => this.scrollTo('gallery')}>Galería</a>
                                <a onClick={() => this.scrollTo('contact')}>Contacto</a>
                            </div>
                            <div className="separated" />
                            <span className="copyright">© 2020 All Rights Reserved</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;