import React, { Component } from 'react';
import './FormContact.scss';
import Loading from '../loading/Loading';
import Alert from '../alert/Alert';

class FormContact extends Component {
    state = {
        loading: false,
        name: '',
        email: '',
        phone: '',
        message: '',
        messageAlert: '',
        messageAlertStatus: false
    }

    sendContact = async (event) => {
        event.preventDefault();
        await this.setState({ loading: true });
        window.Email.send({
            SecureToken: "59b97f55-fe04-419b-bf0c-0bc7c620c2dc",
            To: 'fedemendez2014@icloud.com',
            From: 'contacto@transporteguerra.com.uy',
            Subject: "Contacto desde la web",
            Body: `${this.state.name} ha enviado un mensaje. <br>
            Su email es ${this.state.email} y su número de teléfono es ${this.state.phone}. <br>
            El mensaje es el siguiente: <br><br> ${this.state.message}`
        }).then(
            message => {
                this.setState({ loading: false });
                let alertText = 'Ocurrió un error, reitentar';
                let alertStatus = false;
                if (message === 'OK') {
                    alertText = 'Mensaje enviado con éxito, en breve nos pondremos en contacto';
                    alertStatus = true;
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        message: '',
                    })
                }
                this.showAlert(alertText, alertStatus);
            }
        );
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    showAlert = (message, status) => {
        this.setState({ messageAlert: message, messageAlertStatus: status })
        setTimeout(() => {
            this.setState({ messageAlert: '' })
        }, 6000)
    }

    render() {
        return (
            <div className="row">
                {this.state.loading && <Loading />}
                {this.state.messageAlert !== '' &&
                    <Alert message={this.state.messageAlert} status={this.state.messageAlertStatus} />}
                <div className="col-12">
                    <div className="form-contact">
                        <form onSubmit={this.sendContact}>
                            <div className="input-group mb-4">
                                <input type="text" className="form-control" placeholder="Nombre" required
                                    name="name" value={this.state.name} onChange={this.handleChange} />
                            </div>
                            <div className="input-group mb-4">
                                <input type="email" className="form-control" placeholder="Email" required
                                    name="email" value={this.state.email} onChange={this.handleChange} />
                            </div>
                            <div className="input-group mb-4">
                                <input type="text" className="form-control" placeholder="Teléfono" required
                                    name="phone" value={this.state.phone} onChange={this.handleChange} />
                            </div>
                            <div className="input-group mb-4">
                                <textarea className="form-control" placeholder="Mensaje" rows={6} required
                                    name="message" value={this.state.message} onChange={this.handleChange}></textarea>
                            </div>
                            <div className="input-group mb-4 button-send">
                                <button type="submit">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormContact;