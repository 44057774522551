export const images = [
    { key: 1, src: require('./assets/images/image-guerra-1.jpeg'), thumb: require('./assets/images/thumb/image-guerra-1.jpeg') },
    { key: 2, src: require('./assets/images/image-guerra-2.jpeg'), thumb: require('./assets/images/thumb/image-guerra-2.jpeg') },
    { key: 3, src: require('./assets/images/image-guerra-3.jpeg'), thumb: require('./assets/images/thumb/image-guerra-3.jpeg') },
    { key: 4, src: require('./assets/images/image-guerra-4.jpeg'), thumb: require('./assets/images/thumb/image-guerra-4.jpeg') },
    { key: 5, src: require('./assets/images/image-guerra-5.jpeg'), thumb: require('./assets/images/thumb/image-guerra-5.jpeg') },
    { key: 6, src: require('./assets/images/image-guerra-6.jpeg'), thumb: require('./assets/images/thumb/image-guerra-6.jpeg') },
    { key: 7, src: require('./assets/images/image-guerra-7.jpeg'), thumb: require('./assets/images/thumb/image-guerra-7.jpeg') },
    { key: 8, src: require('./assets/images/image-guerra-8.jpeg'), thumb: require('./assets/images/thumb/image-guerra-8.jpeg') },
    { key: 9, src: require('./assets/images/image-guerra-9.jpeg'), thumb: require('./assets/images/thumb/image-guerra-9.jpeg') },
    { key: 10, src: require('./assets/images/image-guerra-10.jpeg'), thumb: require('./assets/images/thumb/image-guerra-10.jpeg') },
    { key: 11, src: require('./assets/images/image-guerra-11.jpeg'), thumb: require('./assets/images/thumb/image-guerra-11.jpeg') },
    { key: 12, src: require('./assets/images/image-guerra-12.jpeg'), thumb: require('./assets/images/thumb/image-guerra-12.jpeg') },
    { key: 13, src: require('./assets/images/image-guerra-13.jpeg'), thumb: require('./assets/images/thumb/image-guerra-13.jpeg') },
    { key: 14, src: require('./assets/images/image-guerra-14.jpeg'), thumb: require('./assets/images/thumb/image-guerra-14.jpeg') },
    { key: 15, src: require('./assets/images/image-guerra-15.jpeg'), thumb: require('./assets/images/thumb/image-guerra-15.jpeg') },
    { key: 16, src: require('./assets/images/image-guerra-16.jpeg'), thumb: require('./assets/images/thumb/image-guerra-16.jpeg') },
    { key: 17, src: require('./assets/images/image-guerra-17.jpeg'), thumb: require('./assets/images/thumb/image-guerra-17.jpeg') },
    { key: 18, src: require('./assets/images/image-guerra-18.jpeg'), thumb: require('./assets/images/thumb/image-guerra-18.jpeg') },
    { key: 19, src: require('./assets/images/image-guerra-19.jpeg'), thumb: require('./assets/images/thumb/image-guerra-19.jpeg') },
    { key: 20, src: require('./assets/images/image-guerra-20.jpeg'), thumb: require('./assets/images/thumb/image-guerra-20.jpeg') },
    { key: 21, src: require('./assets/images/image-guerra-21.jpeg'), thumb: require('./assets/images/thumb/image-guerra-21.jpeg') },
    { key: 22, src: require('./assets/images/image-guerra-22.jpeg'), thumb: require('./assets/images/thumb/image-guerra-22.jpeg') },
    { key: 23, src: require('./assets/images/image-guerra-23.jpeg'), thumb: require('./assets/images/thumb/image-guerra-23.jpeg') },
    { key: 24, src: require('./assets/images/image-guerra-24.jpeg'), thumb: require('./assets/images/thumb/image-guerra-24.jpeg') },
    { key: 25, src: require('./assets/images/image-guerra-25.jpeg'), thumb: require('./assets/images/thumb/image-guerra-25.jpeg') },
    { key: 26, src: require('./assets/images/image-guerra-26.jpeg'), thumb: require('./assets/images/thumb/image-guerra-26.jpeg') },
    { key: 27, src: require('./assets/images/image-guerra-27.jpeg'), thumb: require('./assets/images/thumb/image-guerra-27.jpeg') },
    { key: 28, src: require('./assets/images/image-guerra-28.jpeg'), thumb: require('./assets/images/thumb/image-guerra-28.jpeg') },
    { key: 29, src: require('./assets/images/image-guerra-29.jpeg'), thumb: require('./assets/images/thumb/image-guerra-29.jpeg') },
    { key: 30, src: require('./assets/images/image-guerra-30.jpeg'), thumb: require('./assets/images/thumb/image-guerra-30.jpeg') },
    { key: 31, src: require('./assets/images/image-guerra-31.jpeg'), thumb: require('./assets/images/thumb/image-guerra-31.jpeg') },
    { key: 32, src: require('./assets/images/image-guerra-32.jpg'), thumb: require('./assets/images/thumb/image-guerra-32.jpg') },
    { key: 33, src: require('./assets/images/image-guerra-33.jpg'), thumb: require('./assets/images/thumb/image-guerra-33.jpg') },
    { key: 34, src: require('./assets/images/image-guerra-34.jpg'), thumb: require('./assets/images/thumb/image-guerra-34.jpg') },
    { key: 35, src: require('./assets/images/image-guerra-35.jpg'), thumb: require('./assets/images/thumb/image-guerra-35.jpg') },
    { key: 36, src: require('./assets/images/image-guerra-36.jpg'), thumb: require('./assets/images/thumb/image-guerra-36.jpg') },
    { key: 37, src: require('./assets/images/image-guerra-37.jpg'), thumb: require('./assets/images/thumb/image-guerra-37.jpg') },
    { key: 38, src: require('./assets/images/image-guerra-38.jpg'), thumb: require('./assets/images/thumb/image-guerra-38.jpg') },
    { key: 39, src: require('./assets/images/image-guerra-39.jpg'), thumb: require('./assets/images/thumb/image-guerra-39.jpg') },
    { key: 40, src: require('./assets/images/image-guerra-40.jpg'), thumb: require('./assets/images/thumb/image-guerra-40.jpg') },
    { key: 41, src: require('./assets/images/image-guerra-41.jpg'), thumb: require('./assets/images/thumb/image-guerra-41.jpg') },
    { key: 42, src: require('./assets/images/image-guerra-42.jpg'), thumb: require('./assets/images/thumb/image-guerra-42.jpg') },
    { key: 43, src: require('./assets/images/image-guerra-43.jpg'), thumb: require('./assets/images/thumb/image-guerra-43.jpg') },
    { key: 44, src: require('./assets/images/image-guerra-44.jpg'), thumb: require('./assets/images/thumb/image-guerra-44.jpg') }
];