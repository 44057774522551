import React, { Component } from 'react';
import './Contact.scss';

import iconLocation from '../../assets/icons/map-marker-alt-solid.svg';
import iconPhone from '../../assets/icons/phone-solid-dark.svg';
import iconEnvelope from '../../assets/icons/envelope-solid-dark.svg';
import iconRedes from '../../assets/icons/comment-alt-solid.svg';
import FormContact from './FormContact';
import GoogleMap from './GoogleMap';

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container separated-section contact-section" id="contact">
                    <div className="row">
                        <div className="col-12 title-section">
                            <h4>CONTACTO</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 order-2 order-md-1">
                            <FormContact />
                        </div>
                        <div className="col-12 col-md-6 company-data order-1 order-md-2">
                            <div className="row">
                                <div className="col-12 col-md-8 box">
                                    <div className="item">
                                        <img src={iconLocation} />
                                        <span>Dirección</span>
                                    </div>
                                    <a target="_blank" href="https://www.google.com/maps/place/Rinc%C3%B3n+del+Pino,+Departamento+de+San+Jos%C3%A9/@-34.5023878,-56.8368244,17z/data=!3m1!4b1!4m5!3m4!1s0x95a181591761822f:0x2933effb32371c76!8m2!3d-34.502788!4d-56.8346253">
                                        Rincón del Pino, San José
                                    </a>
                                </div>
                                <div className="col-12 col-md-4 box">
                                    <div className="item">
                                        <img src={iconPhone} />
                                        <span>Teléfono</span>
                                    </div>
                                    <a href="tel:+59899000000">+598 99 298 327</a>
                                    <br />
                                    <a href="tel:+59899000000">+598 91 209 496</a>
                                </div>
                                <div className="col-12 col-md-8 box">
                                    <div className="item">
                                        <img src={iconEnvelope} />
                                        <span>Email</span>
                                    </div>
                                    <a href="mailto:transp-guerra@hotmail.com">transp-guerra@hotmail.com</a>
                                    <br />
                                    <a href="mailto:transp-guerra@hotmail.com">facuguerra9@gmail.com</a>
                                </div>
                                <div className="col-12 col-md-4 box">
                                    <div className="item">
                                        <img src={iconRedes} />
                                        <span>Redes</span>
                                    </div>
                                    <a target="_blank" href="https://www.facebook.com/facundo.guerra.12/">Facebook</a>
                                    <br />
                                    <a target="_blank" href="https://www.instagram.com/grupodetransportegeraldguerra/">Instagram</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 map">
                            <GoogleMap />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Contact;